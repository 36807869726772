// eslint-disable-next-line import/no-extraneous-dependencies
import { SwaggerUIBundle } from 'swagger-ui-dist';

const initSwagger = (): void => {
  SwaggerUIBundle({
    url: './api.yaml',
    dom_id: '#swagger',
    deepLinking: true,
  });
};

export default initSwagger;
